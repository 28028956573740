console.log('JS OK 3');


//-------------------------------------//
/* Fonction des filtres du header */
var btnFiltres = document.querySelector('#boutonFiltres');
var btnFiltresMobile = document.querySelector('.filtreSpan');
var menuFiltre = document.querySelector('.filtres');

btnFiltres.addEventListener('click', function(){
  btnFiltres.classList.toggle('tourne');
 menuFiltre.classList.toggle('ouvert');
  menuFiltre.classList.toggle('none');
});

btnFiltresMobile.addEventListener('click', function(){
  btnFiltres.classList.toggle('tourne');
  menuFiltre.classList.toggle('ouvert');
  menuFiltre.classList.toggle('none');
});


//-------------------------------------//
/* Header qui remonte en scrollant */
var le_html = document.querySelector('html');
var prevScrollpos = window.pageYOffset;
var header = document.querySelector('header');
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;

  if (prevScrollpos > currentScrollPos) {
  //Scroll remontant
    // console.log('1- scroll remonte : ' + currentScrollPos)
    header.style.transform = "translateY(0px)";

    //Fondu du header vers le rien pour la fiche projet
    if (currentScrollPos > 100){
      header.classList.add('blanc', 'sticky');
      menuFiltre.classList.add('blanc');
    }else{
      header.classList.remove('blanc', 'sticky');
      menuFiltre.classList.remove('blanc');
    }

  }else {
    //Scroll descendant
    header.style.transform = "translateY(-120px)";
  }
  prevScrollpos = currentScrollPos;
}


//-------------------------------------//
/* Fonction du header burger mobile */
var btnMenu = document.querySelector('.burger');
var menuMobile = document.querySelector('.navigationMenu');

btnMenu.addEventListener('click', function(){
  btnMenu.classList.toggle('open');
  menuMobile.classList.toggle('ouvert');
  le_html.classList.toggle('ouvertMobile');

  //Ajout du fond blanc quand on ouvre le menu mobile en haut de page
  if (btnMenu.classList.contains('open') && !header.classList.contains('sticky') ) {
    header.classList.add('blanc');
  };
  if (!btnMenu.classList.contains('open') && !header.classList.contains('sticky')) {
    header.classList.remove('blanc');
  };


});

//-------------------------------------//
/* Fonction des portraits de l'équipe */

var portrait = document.querySelectorAll(".portrait");

portrait.forEach( function(lePortrait) {
    var popup = lePortrait.querySelector('.equipePopUp');

    //Ouvrir
    var btnPortrait = lePortrait.querySelector('.btnPortrait');
    btnPortrait.addEventListener('click', function(){
      openClosePopup(popup);    
    });

    //Fermer
    var fond = lePortrait.querySelector('.equipePopUpOut');
    fond.addEventListener('click', function(){
      openClosePopup(popup);    
    });
    var clickObject = lePortrait.querySelector('.clickObject');
    clickObject.addEventListener('click', function(){
      openClosePopup(popup);    
    });

});

var openClosePopup = function(lePop){
    var displaySetting = lePop.style.display;
    if (displaySetting == 'flex') {
      lePop.style.display = 'none';
    }
    else {
      lePop.style.display = 'flex';
    }
}


//-------------------------------------//
// UNIQUEMENT SUR FICHE PROJETS =
// ouvrir la suite du texte + la fitech 

var ficheProjet = document.querySelector(".single-post");
if ( ficheProjet ) {

  // var introProjetText = document.querySelector('.IntroProjetText');
  var btnPlus = document.querySelector('.plusInfos');
  var suiteTexte = document.querySelector('.textHidden');
  var infosProjet = document.querySelector('#infosProjet');
  var posIntroScroll = document.querySelector('#intro').offsetTop;

  btnPlus.addEventListener('click', function(){
    ouvrirFermerFitech();
    });
  

var ouvrirFermerFitech = function(){

    //Si on referme le texte, scroller jusqu'à l'intro
    if (btnPlus.classList.contains('ouvert')) {
      window.scrollTo({
        top: posIntroScroll-150,
        behavior: "smooth",
      });
    }
    // infosProjet.classList.toggle('ouvert');
    btnPlus.classList.toggle('ouvert');
    suiteTexte.classList.toggle('ouvert');

}



}



//-------------------------------------//
/* Recherche */
function toggleSearch() {
var mySearch = document.getElementById("searchli");
var widthSetting = mySearch.style.width;
var bye = document.getElementById("bye");
var hey = document.getElementById("hey");
mySearch.classList.toggle("searchbig");
bye.classList.toggle("byebye");
hey.classList.toggle("heyhey");
mySearch.querySelector(".textNews").focus();
}


//-------------------------------------//
//LISTE INDEX PROJET

var indexPage = document.querySelector(".page-id-37");
if ( indexPage ) {
  // console.log('page index');

/* Fonction qui déplie les projets dans l'index */
const elem_a_deplier = document.querySelectorAll(".projetIndexSmall a");

elem_a_deplier.forEach(function (itemSelect) {
  itemSelect.addEventListener("click", function(event){
    event.preventDefault();
    itemSelect.parentNode.classList.toggle("max");
    console.log('click');
  });
});
}


//-------------------------------------//
// Infinite Scroll
var pageAvecInfiniteScroll = document.querySelector('.infiniteContainer');
if (pageAvecInfiniteScroll) {
var infScroll = new InfiniteScroll( pageAvecInfiniteScroll, {
    path: '.nextPageLink a',
    append: '.articleInfinite',
    status: '.page-load-status',
    history: false,
    hideNav: '.nextPrev',
    debug: false
});
}